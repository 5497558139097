import React, { Component } from "react"
import * as JsSearch from "js-search"
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import FixedMenu from "./FixedMenu" 
import SiteContext from "../context/SiteContext"

class ClientSearch extends Component {
    state = {
        isLoading: true,
        searchResults: [],
        resultCounts: {},
        search: null,
        isError: false,
        indexByTitle: false,
        termFrequency: true,
        removeStopWords: false,
        searchQuery: "",
        selectedStrategy: "All",
        selectedSanitizer: "",
        showProducts:true,
        showSystems:true,
        showProductBulletins: true,
        showTechnicalBulletins: true,
        showSystemOverviews: true,
        showSpecifications: true,
        showDetails: true
    }
    /**
     * React lifecycle method that will inject the data into the state.
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.search === null) {
            const { engine } = nextProps
            return {
                indexByTitle: engine.TitleIndex,
                indexBySummary: engine.SummaryIndex,
                indexByOldProductName: engine.OldProductNameIndex,
                termFrequency: engine.SearchByTerm,
                selectedSanitizer: engine.searchSanitizer,
                selectedStrategy: engine.indexStrategy,
            }
        }
        return null
    }
    async componentDidMount() {
        try {
            await this.rebuildIndex()
            const searchQuery = this.props.query.query
            const searchObj = {
                target: {
                    value: searchQuery
                }
            }

            if (searchQuery) {
                this.setState({ searchQuery })
                this.searchData(searchObj)
            }
        } catch (err) {
            console.error(err)
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            try {
                await this.rebuildIndex()
                const searchQuery = this.props.query.query
                const searchObj = {
                    target: {
                        value: searchQuery
                    }
                }
                
                if (searchQuery) {
                    this.setState({ searchQuery })
                    this.searchData(searchObj)
                }
            } catch (err) {
                console.error(err)
            }
        }
    }

    /**
     * rebuilds the overall index based on the options
     */
    rebuildIndex = () => {
        const {
            selectedStrategy,
            selectedSanitizer,
            removeStopWords,
            termFrequency,
            indexByTitle,
            indexBySummary,
            indexByOldProductName,
        } = this.state
        const { products, systems, productBulletins, technicalBulletins, systemOverviews, specifications, details  } = this.props
        const searchItems = { products, systems, productBulletins, technicalBulletins, systemOverviews, specifications, details }

        const dataToSearch = new JsSearch.Search("title")
        if (removeStopWords) {
            dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
                dataToSearch.tokenizer
            )
        }
        /**
         * defines an indexing strategy for the data
         * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
         */
        if (selectedStrategy === "All") {
            dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
        }
        if (selectedStrategy === "Exact match") {
            dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy()
        }
        if (selectedStrategy === "Prefix match") {
            dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
        }
        /**
         * defines the sanitizer for the search
         * to prevent some of the words from being excluded
         */
        selectedSanitizer === "Case Sensitive"
            ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
            : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
        termFrequency === true
            ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id"))
            : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())
        // sets the index attribute for the data
        if (indexByTitle) {
            dataToSearch.addIndex("title")
        }
        if (indexBySummary) {
            dataToSearch.addIndex("summary")
        }
        if (indexByOldProductName) {
            dataToSearch.addIndex("oldProductName")
        }
        dataToSearch.addDocuments(products) // adds the data to be searched
        dataToSearch.addDocuments(systems) // adds the data to be searched
        dataToSearch.addDocuments(productBulletins) // adds the data to be searched
        dataToSearch.addDocuments(technicalBulletins) // adds the data to be searched
        dataToSearch.addDocuments(systemOverviews) // adds the data to be searched
        dataToSearch.addDocuments(specifications) // adds the data to be searched
        dataToSearch.addDocuments(details) // adds the data to be searched

        this.setState({ search: dataToSearch, isLoading: false })
    }
    /**
     * handles the input change and perform a search with js-search
     * in which the results will be added to the state
     */
    searchData = e => {
        const { search } = this.state
        console.log(e.target.value)
        const queryResults = search.search(e.target.value)
        console.log('results', queryResults)
        let resultCounts = {
            'products': 0,
            'systems': 0,
            'productBulletins': 0,
            'technicalBulletins': 0,
            'systemOverviews': 0,
            'specifications': 0,
            'details': 0
        }

        queryResults.map(result => {
            if (result.type === 'product') {
                resultCounts['products']++
            } else if (result.type === 'system') {
                resultCounts['systems']++
            } else if (result.type === 'productBulletin') {
                resultCounts['productBulletins']++
            } else if (result.type === 'technicalBulletin') {
                resultCounts['technicalBulletins']++
            } else if (result.type === 'systemOverview') {
                resultCounts['systemOverviews']++
            } else if (result.type === 'specification') {
                resultCounts['specifications']++
            } else if (result.type === 'details') {
                resultCounts['details']++
            }
        })

        console.log('counts', resultCounts)

        this.setState({ searchQuery: e.target.value, searchResults: queryResults, resultCounts })
    }
    handleSubmit = e => {
        e.preventDefault()
    }

    renderProductSearchResults(queryResults, site) {
        if (queryResults.length > 0 ){
            return (
                queryResults.map(item => {
                    return item.type === 'product'
                    ? (
                            <a className={`download senergy`} href={`/product/${item.slug}`}>
                                <div className="download__copy">
                                    {/* {item.oldProductName ? <span className="subtitle">Formerly: {item.oldProductName}</span> : '' } */}
                                    <span className="download__title"> <span className="left"></span> {item.title}</span>
                                </div>
                                <div className={`download__link senergy`}><IcomoonReact iconSet={iconSet} size={20} icon="arrow-right2" /></div>
                            </a>
                    )
                    : null
                })
            )
        } else if (this.state.showProducts) {
            // this.setState({ showProducts : false })
        }
    }

    renderSystemSearchResults(queryResults, site) {
        if (queryResults.length > 0 ){
            return (
                queryResults.map(item => {
                    return item.type === 'system'
                        ? (
                            <a className={`download senergy`} href={`/system/${item.slug}`}>
                                {/* <span className="subtitle">{result.searchResults.type[0].toUpperCase() + result.searchResults.type.substring(1)}</span> */}
                                <div className="download__copy">
                                    {/* {item.oldProductName ? <span className="subtitle">Formerly: {item.oldProductName}</span> : '' } */}
                                    <span className="download__title"> <span className="left"></span> {item.title}</span>
                                </div>
                                <div className={`download__link senergy`}><IcomoonReact iconSet={iconSet} size={20} icon="arrow-right2" /></div>
                            </a>
                        )
                        : null
                })
            )
        } else if (this.state.showSystems) {
            // console.log("hide systems");
            // this.setState({ showSystems : false })
        }
    }

    renderLiteratureSearchResults(queryResults, site, type) {
        if (queryResults.length > 0) {
            return (
                queryResults.map(item => {
                    return item.type === type
                        ? (
                            <a className={`download senergy`} href={`${item.externalLink ? item.externalLink : item.filepath}`}>
                                {/* <span className="subtitle">{result.searchResults.type[0].toUpperCase() + result.searchResults.type.substring(1)}</span> */}
                                <div className="download__copy">
                                    {/* {item.oldProductName ? <span className="subtitle">Formerly: {item.oldProductName}</span> : '' } */}
                                    <span className="download__title"> <span className="left"></span> {item.title}</span>
                                </div>
                                <div className={`download__link senergy`}><IcomoonReact iconSet={iconSet} size={20} icon="arrow-right2" /></div>
                            </a>
                        )
                        : null
                })
            )
        } 
    }

    render() {
        const { searchResults, searchQuery } = this.state
        const { products, categories, systems, productBulletins, technicalBulletins, systemOverviews, specifications, details, site } = this.props
        const queryResults = searchResults
        
        return (
            <SiteContext.Consumer>
            {siteContext => (
            <div className="wrapper app-header-style-text">
                <div className="content-wrapper">
                    <div className="app-header">
                        <div className="app-header-content">
                            <h1 className="app-header__title">Search Results</h1> 
                            <div className="spacer"></div>
                        </div>
                    </div>  
                    <div className="search-results-wrapper">
                    
                        {
                            this.state.resultCounts['products'] > 0
                                ? <div className="search-group">
                                    <div className={`search-group__header ${this.state.showProducts ? 'search-group--visible' : 'search-group--hide' }`}>
                                        <IcomoonReact className="search-group__icon" iconSet={iconSet} size={22} icon="products" color="#ffc510" />
                                        <div className="search-group__title">Products</div>
                                    </div>
                                    <div className="search-group__result">
                                        {this.renderProductSearchResults(queryResults, site)}
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.state.resultCounts['systems'] > 0 
                                ? <div className="search-group">
                                    <div className={`search-group__header ${this.state.showSystems ? 'search-group--visible' : 'search-group--hide'}`}>
                                        <IcomoonReact className="search-group__icon" iconSet={iconSet} size={24} icon="systems" color="#ffc510" />
                                        <div className="search-group__title">Systems</div>
                                    </div>
                                    <div className="search-group__result">
                                        {this.renderSystemSearchResults(queryResults, site)}
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.state.resultCounts['productBulletins'] > 0 
                                ? <div className="search-group">
                                    <div className={`search-group__header ${this.state.showProductBulletins ? 'search-group--visible' : 'search-group--hide'}`}>
                                        <IcomoonReact className="search-group__icon" iconSet={iconSet} size={24} icon="product-bulletins" color="#ffc510" />
                                        <div className="search-group__title">Product Bulletins</div>
                                    </div>
                                    <div className="search-group__result">
                                        {this.renderLiteratureSearchResults(queryResults, site, 'productBulletin')}
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.state.resultCounts['technicalBulletins'] > 0 
                                ? <div className="search-group">
                                    <div className={`search-group__header ${this.state.showTechnicalBulletins ? 'search-group--visible' : 'search-group--hide'}`}>
                                        <IcomoonReact className="search-group__icon" iconSet={iconSet} size={24} icon="technical-bulletins" color="#ffc510" />
                                        <div className="search-group__title">Technical Bulletins</div>
                                    </div>
                                    <div className="search-group__result">
                                        {this.renderLiteratureSearchResults(queryResults, site, 'technicalBulletin')}
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.state.resultCounts['systemOverviews'] > 0 
                                ? <div className="search-group">
                                    <div className={`search-group__header ${this.state.showSystemOverviews ? 'search-group--visible' : 'search-group--hide'}`}>
                                        <IcomoonReact className="search-group__icon" iconSet={iconSet} size={24} icon="system-overviews" color="#ffc510" />
                                        <div className="search-group__title">System Overviews</div>
                                    </div>
                                    <div className="search-group__result">
                                        {this.renderLiteratureSearchResults(queryResults, site, 'systemOverview')}
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.state.resultCounts['specifications'] > 0 
                                ? <div className="search-group">
                                    <div className={`search-group__header ${this.state.showSpecifications ? 'search-group--visible' : 'search-group--hide'}`}>
                                        <IcomoonReact className="search-group__icon" iconSet={iconSet} size={24} icon="specifications" color="#ffc510" />
                                        <div className="search-group__title">Specifications</div>
                                    </div>
                                    <div className="search-group__result">
                                        {this.renderLiteratureSearchResults(queryResults, site, 'specification')}
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.state.resultCounts['details'] > 0 
                                ? <div className="search-group">
                                    <div className={`search-group__header ${this.state.showDetails ? 'search-group--visible' : 'search-group--hide'}`}>
                                        <IcomoonReact className="search-group__icon" iconSet={iconSet} size={24} icon="details" color="#ffc510" />
                                        <div className="search-group__title">Details</div>
                                    </div>
                                    <div className="search-group__result">
                                        {this.renderLiteratureSearchResults(queryResults, site, 'details')}
                                    </div>
                                </div>
                                : null
                        }
                  
                    </div>
                </div>
                <FixedMenu active="" site={siteContext.site} /> 
            </div>
            )}
        </SiteContext.Consumer>
        )
    }
}
export default ClientSearch